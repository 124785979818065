import React from 'react';

interface DebounceRef<T> {
  fn: T;
  wait: number;
  promise?: Promise<any>;
  resolve?: (value?: any) => void;
  reject?: (reason?: any) => void;
  timeout?: any; // number | NodeJS.Timeout;
}

export default function useAsyncDebounce<T extends (...args: any[]) => any>(
  fn: T,
  wait: DebounceRef<T>['wait'] = 0,
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  const debounceRef = React.useRef<DebounceRef<T>>({
    fn,
    wait,
  });

  return React.useCallback(async (...args: Parameters<T>) => {
    if (!debounceRef.current.promise) {
      debounceRef.current.promise = new Promise((resolve, reject) => {
        debounceRef.current.resolve = resolve;
        debounceRef.current.reject = reject;
      });
    }

    if (debounceRef.current.timeout) {
      clearTimeout(debounceRef.current.timeout);
    }

    debounceRef.current.timeout = setTimeout(async () => {
      delete debounceRef.current.timeout;
      try {
        debounceRef.current.resolve(await debounceRef.current.fn(...args));
      } catch (err) {
        debounceRef.current.reject(err);
      } finally {
        delete debounceRef.current.promise;
      }
    }, debounceRef.current.wait);

    return debounceRef.current.promise;
  }, []);
}
